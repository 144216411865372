import React from 'react';

import PostListAuthor from './ListAuthor';

const RelatedPosts = ({ authorName, posts }) => {
  if (!posts.length) {
    return false;
  }

  return (
    <div className="related-posts">
      <div className="related-posts__title">
        <h3>
          More by
          { ' ' }
          { authorName }
          { ' ' }
        </h3>
      </div>
      <div className="related-posts__media">
        <PostListAuthor posts={ posts } />
      </div>
    </div>
  );
};

export default RelatedPosts;
