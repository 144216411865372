import React from 'react';
import SwiperCore, { Navigation, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyLoad from 'react-lazyload';

SwiperCore.use([ Navigation, Zoom ]);

const Gallery = ({ files }) => (
  <LazyLoad height={ 200 }>
    <Swiper
      spaceBetween={ 20 }
      navigation
      slidesPerView="auto"
      zoom
    >
      { files.map((file, keyImg) => (
        <SwiperSlide
          key={ `item-${keyImg * keyImg}` }
          zoom
        >
          <img src={ file.url } />
        </SwiperSlide>
      )) }
    </Swiper>
  </LazyLoad>
);
export default Gallery;
