/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Author from '../components/authors/Author';
import RelatedPosts from '../components/posts/RelatedPosts';
import Subposts from '../components/posts/Subposts';
import MediaRow from '../components/posts/MediaRow';

const Post = ({ data }) => {
  const post = data.post.data;
  const relatedPosts = data.relatedPosts.edges;

  let coAuthors = [];

  if (post.Author.length > 1) {
    coAuthors = post.Author.slice(1);
  }

  return (
    <Layout pageClass="page--full">
      <SEO
        title={ post.Title }
      />
      <div className="post">
        <div className="post__header">
          <div className="post__author">
            <div>{ post.Date }</div>
            { post.Author && <Author author={ post.Author[0] } /> }
          </div>
          <div className="post__intro">
            <h1 className="post__title">
              { post.Title }
              { ' ' }
              <small>{ post.Category }</small>
            </h1>

            { post.Text && (
              <div
                className={ `markdown ${post.Text_Center ? 'center' : ''}` }
                dangerouslySetInnerHTML={ {
                  __html: post.Text.childMarkdownRemark.html,
                } }
              />
            ) }

            { coAuthors && (
              <div className="post__co-authors">
                { coAuthors.map((author, key) => (
                  <div className="post__co-authors__item" key={ `item-${key * key}` }>
                    <Author key={ author.data.Name } author={ author } />
                  </div>
                )) }
              </div>
            ) }

          </div>
        </div>

        <MediaRow
          title={ post.Media_Title }
          gallery={ post.Gallery }
          video={ post.Video }
        />

        { post.Subposts
          && <Subposts posts={ post.Subposts } /> }

        <div className="post__after">
          { post.Appendix && (
            <div
              className={ `post__appendix markdown ${post.Appendix_Center ? 'center' : ''}` }
              dangerouslySetInnerHTML={ {
                __html: post.Appendix.childMarkdownRemark.html,
              } }
            />
          ) }

          <div className="post__related">
            <RelatedPosts authorName={ post.Author[0].data.Name } posts={ relatedPosts } />
          </div>
        </div>

      </div>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query($id: String!, $author: String) {
    post: airtable(id: { eq: $id }) {
      id
      data {
        Title
        Category
        Media_Title
        Video
        Date(formatString: "DD.MM.YYYY")
        Text {
          childMarkdownRemark {
            html
          }
        }
        Text_Center
        Appendix {
          childMarkdownRemark {
            html
          }
        }
        Appendix_Center
        Thumbnail {
          id
          localFiles {
            childImageSharp {
              fixed(width: 400) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        Gallery {
          localFiles {
            url
          }
        }
        Author {
          data {
            Name
            Passion
            Specialism
            Disable_page
            Picture {
              localFiles {
                extension
                url
                childImageSharp {
                  fluid(maxHeight: 237) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
        Subposts {
          data {
            Title
            Media_Title
            Gallery {
              localFiles {
                url
              }
            }
            Video
          }
        }
      }
    }
    relatedPosts: allAirtable(filter: {table: {eq: "Posts"}, data: {Author: {elemMatch: {data: {Name: {eq: $author}}}}}, id: {ne: $id}}) {
      edges {
        node {
          id
          data {
            Title
            Category
            Date(formatString: "DD.MM.YYYY")
            Thumbnail {
              localFiles {
                extension
                url
                childImageSharp {
                  fluid(maxHeight: 237) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
