import React from 'react';
import MediaRow from './MediaRow';

const Subposts = ({ posts }) => (
  <>
    { posts.map((post, key) => (
      <MediaRow
        key={ `item-${key * key}` }
        title={ post.data.Media_Title }
        gallery={ post.data.Gallery }
        video={ post.data.Video }
      />
    )) }
  </>
);

export default Subposts;
