import React from 'react';
import Gallery from './Gallery';
import VideoPlayer from './VideoPlayer';

const MediaRow = ({
  title, gallery, video,
}) => (
  <div className="mediarow">
    <div className="mediarow__title">
      { title && <h2>{ title }</h2> }
    </div>
    <div className="mediarow__media">
      { gallery && gallery.localFiles && <Gallery files={ gallery.localFiles } /> }
      { video && <VideoPlayer video={ video } /> }
    </div>
  </div>
);

export default MediaRow;
